@import url('https://fonts.googleapis.com/css2?family=Fira+Code:wght@400;600;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    font-family: 'Fira Code', monospace;
    @apply bg-gray-50;
}
